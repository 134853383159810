import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import { Helmet } from "react-helmet";

import BannerSlider from "./Bannerslider";
import Insta from "./insta";
import Customer from "./Customer";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";
import DiamondRing from "./DiamondRing";
import ExploreCollectionDfl from "./ExploreCollectionDfl";
import DiamondCollectionVideo from "./DiamondCollectionVideo";
import DflShopByShape from "./DflShopByShape";
import TrendingRings from "./TrendingRings";
import ViewCustomer from "./ViewCustomer";
import LooseDiamond from "./LooseDiamond";
import { isDesktop } from "react-device-detect";
import GoogleReviews from "../../Components/Reviews";
import BannerWithTextAndVideo from "./BannerWithTextAndVideo";
import TrandingNowCenterSlider from "./TrandingNowCenterSlider";
import ChristmassBanner from "./ChristmassBanner";
import HoliDaysHeading from "./HoliDaysHeading";
import FrontHeading from "./FrontHeading";
import HeroBanner from "./HeroBanner";

const Home = () => {
  const metaDetails = useSelector((state) => state.persistedReducer.meta.meta);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      // disable: 'mobile'
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails?.title}</title>
        <meta name="description" content={metaDetails?.description}></meta>
        <meta name="keywords" content={metaDetails?.keywords}></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaDetails?.title} />
        <meta property="og:description" content={metaDetails?.description} />
        <meta property="og:url" content={metaDetails?.url} />
        <meta property="og:site_name" content="Diamond For Less" />
      </Helmet>

      <div className="dfl_main_bg">
        {/* <BannerSlider url={isDesktop ? 'https://dl2vs6wk2ewna.cloudfront.net/media/bannerVid.mp4' : 'https://dl2vs6wk2ewna.cloudfront.net/media/bannerVidMob.mp4'} /> */}
        <BannerWithTextAndVideo />
        {/* <HeroBanner /> */}
        {/* <DiamondRing /> */}
        {/* <HoliDaysHeading /> */}
        {/* <ChristmassBanner/> */}
        <FrontHeading />
        <Customer />
        {/* <TrendingRings /> */}
        <TrandingNowCenterSlider />

        {/* <DiamondCollectionVideo /> */}

        <DflShopByShape />
        <ViewCustomer />
        <BannerSlider
          height="75vh"
          className="dfl_video_height"
          title="Handcrafted Locally To The Highest Standards"
          url="https://dl2vs6wk2ewna.cloudfront.net/media/diamondsforless-1.mp4"
        />
        <ExploreCollectionDfl container={true} />
        <LooseDiamond />
        <Insta />
        {/* <GoogleReviews/> */}
      </div>
    </>
  );
};

export default React.memo(Home);
